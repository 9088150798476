<template>
  <div>
    <el-dialog
      :title="`${isEdit ? '修改' : '添加'}`"
      :visible.sync="show"
      :before-close="handleClose"
      @open="getVenuList"
      width="500px"
    >
      <el-form :model="form" ref="form" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="闸机名称"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input
                v-model="form.name"
                clearable
                placeholder="请输入闸机名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="所属场馆"
              :label-width="formLabelWidth"
              prop="gateVenueId"
            >
              <el-select v-model="form.gateVenueId" placeholder="请选择所属场馆" style="width:100%">
                <el-option v-for="(item) in venueList" :value="item.id" :label="item.venueName" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="中控设备"
              :label-width="formLabelWidth"
              prop="deviceId"
            >
              <el-select v-model="form.deviceId" placeholder="请选择中控设备" style="width:100%" clearable>
                <!-- <el-option label="设备1234" value="1234"></el-option>
                <el-option label="设备5678" value="5678"></el-option>
                <el-option label="设备222" value="222"></el-option>
                <el-option label="设备333" value="333"></el-option>
                <el-option label="设备44" value="44"></el-option>
                <el-option label="设备5" value="5"></el-option>
                <el-option label="设备6" value="6"></el-option>
                <el-option label="设备7" value="7"></el-option>
                <el-option label="设备8" value="8"></el-option>
                <el-option label="设备9" value="9"></el-option>
                <el-option label="设备10" value="10"></el-option> -->
                <el-option v-for="(item, index) in deviceList" :key="index" :value="item.id" :label="`${item.deviceName}-${item.deviceSn}`"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="进出控制"
              :label-width="formLabelWidth"
              prop="passageType"
            >
              <el-select v-model="form.passageType" placeholder="请选择进出控制" style="width:100%" clearable>
                <el-option label="进入" value="ENTER"></el-option>
                <el-option label="离开" value="OUT"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item
              label="验证方式"
              :label-width="formLabelWidth"
              prop="verification"
            >
              <el-checkbox-group v-model="form.verification" >
                <el-checkbox label="二维码" style="display:block;"></el-checkbox>
                <el-checkbox label="人脸" style="display:block;"></el-checkbox>
                <el-checkbox label="IC卡" style="display:block;"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="comfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      require: true,
      type: Boolean,
    },
    isEdit: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"),
      rules: new AddDialogClass("rule"), // 实例化一个表单的规则
      formLabelWidth: "120px",
      checkList: [],
      venueList: [],
      deviceList: [],
    };
  },

  mounted() {
    this.getDeviceList()
  },

  methods: {
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
    },
    handleClose() {
      this.$emit("changeShow", false);
      this.init();
    },
    comfirm() {
      const formData = { ...this.form };
      console.log(formData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post(this.isEdit ? apis.updateGate : apis.addGate, formData).then((res) => {
            if (res.data.code === 0) {
              this.handleClose()
              // this.$message.success("添加成功！");
            }
          })
        }
      });
    },
    getDataQuery(data) {
      console.log('1111', data[0]);
      this.form.name = data[0].name;
      this.form.gateVenueId = data[0].venueId;
      this.form.deviceId = data[0].deviceId;
      this.form.passageType = data[0].passageType;
      this.form.id = data[0].id
    },
    getVenuList() {
      return new Promise((resolve, reject) => {
        this.$http.post(apis.venuevManagement_list, { id: "" }).then((res) => {
          if (res.data.code === 0) {
            console.log('场馆列表', res.data.rows);
            this.venueList = res.data.rows
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    getDeviceList() {
      console.log(123);
      this.$http.get(apis.getDeviceInfo).then((res) => {
          if (res.data.code === 0) {
            console.log('设备列表', res.data.data);
            this.deviceList = res.data.data
          }
        })
    },
  },
  activated() {
    this.getVenuList()
  },
};
</script>

<style lang="scss" scoped>
</style>
