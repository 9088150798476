/*
 * @Author: your name
 * @Date: 2021-08-12 18:40:43
 * @LastEditTime: 2021-08-24 14:16:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\gateManagement\gateList\dialog\addDialogClass.js
 */
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.name = ''
      this.gateVenueId = ''
      this.deviceSn = ''
      this.deviceId = ''
      this.passageType = ''
      // this.verification = []
    } else if (type === 'rule') {
      this.name = [{ required: true, message: '请输入闸机名称', trigger: 'blur' }]
      this.gateVenueId = [{ required: true, message: '请选择所属场馆', trigger: ['change', 'blur'] }]
      this.deviceId = [{ required: true, message: '请选择中控设备', trigger: ['change', 'blur'] }]
      this.passageType = [{ required: true, message: '请选择进出控制', trigger: ['change', 'blur'] }]
      // this.verification = [{ required: true, message: '请选择验证方式', trigger: ['change', 'blur'] }]
    }
  }
}
export default searchFrom
