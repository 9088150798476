/*
 * @Author: zengzewei
 * @Date: 2021-08-12 18:32:57
 * @LastEditTime: 2021-09-02 14:57:28
 * @LastEditors: zengzewei
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\gateManagement\gateList\searchClass.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.deviceSn = ''
      this.name = ''
      this.venueName = ''
    }
  }
}
export default searchFrom
