<template>
    <div>
        <el-dialog
            :visible.sync="show"
            @close="handleClose"
            width="30%"
            center
        >
            <div class="dialog-wrap">
                <i class="el-icon-warning"></i>
                <p class="system-tip">系统提示</p>
                <p>您确认要删除该闸机吗？</p>
                <!-- <div class="" v-if="`confirmDialog_other${type}`">{{$t(`confirmDialog_other${type}`)}}</div> -->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="delComfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import mixin from "@/mixins/dialogMixin";

export default {
    mixins: [mixin],
    props: {
        show: {
            require: true,
            type: Boolean,
        },
    },
    data() {
        return {
            formLabelWidth: '120px',
            checkList: [],
        };
    },

    mounted() {
    },

    methods: {
        handleClose() {
            this.$emit('changeShow', false)
        },
        // getDataQuery(data) {
            // console.log(data[0]);
            // this.delComfirm(data[0])
        // },
        delComfirm() {
            // console.log(data1);
            this.$emit('sureDialog')
        },
    },
};
</script>

<style lang="less" scoped>
@import url('../../../../assets/styles/mixin.scoped.less');
/deep/.el-dialog__header{
    border-bottom: 0;
}
.dialog-wrap{
    text-align: center;
    i{
        font-size: 50px;
        color: #F7B500;
    }
    .system-tip{
        font-size: 18px;
        margin-bottom: 20px;
    }
    p{
        margin: 30px 0;
    }
}
</style>
